<template>
  <div>
    <PaymentMethod
      v-for="(payment, index) in bill.payments"
      :key="index"
      :useTax="useTax"
      :type="bill.type"
      :splitted="bill.payments?.length > 1"
      :validated="validated"
      :cortesia="cortesia"
      :payment="payment"
      :paymentMethods="paymentMethodsFiltered"
      :bankAccounts="bankAccounts"
      :totalAmount="totalAmount"
      :hasMoreOne="bill.payments?.length > 1"
      :isRecurrence="bill.recurrence"
      @remove="removePaymentOption"
      :isCreditCategory="isCreditCategory"
    />

    <div class="remaining-to-pay" v-if="showRemaningValueToPay">
      <p>{{ remainingValueToPayText }}</p>
    </div>

    <div class="payments-footer">
      <b-button
        class="new-payment-button"
        v-if="bill.id === null"
        variant="outline-primary"
        @click="addNewPaymentOption"
      >
        Adicionar forma de pagamento
      </b-button>
      <div v-if="bill.payments && bill.payments?.length" class="total">

        <ValueTotal 
          label="Crédito:" 
          :value="totalValueCreditPatient" 
          v-if="hasCreditPatient"
        />
        <ValueTotal label="Total:" :value="getTotal()" />
        <ValueTotal
          v-if="onlendingExtract"
          label="Valor repasse:"
          :value="onlendingValue"
        />
        <ValueTotal
          v-if="useTax"
          class="green-total"
          label="Total c/ desc.:"
          :value="getTotalWithTax()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    useTax: { type: Boolean, default: false },
    validated: Boolean,
    cortesia: Boolean,
    bill: { type: Object, required: true },
    totalAmount: Number,
    onlendingExtract: Boolean,
    onlendingValue: Number,
    hasCreditPatient: Boolean,
    isCreditCategory: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PaymentMethod: () => import('@/modules/financial/components/PaymentMethod'),
    ValueTotal: () => import('@/components/General/ValueTotal')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      paymentMethods: [],
      bankAccounts: [],
      firstTime: true,
      patientCreditPaymentMethod: null
    }
  },
  created() {
    this.getPaymentMethods()
    this.getBankAccounts()
  },
  mounted() {
    this.bill.payments = [this.getDefaultPaymentMethod()]
    if (this.totalAmount) {
      this.bill.payments[0].amount = this.totalAmount
    }
  },
  computed: {
    showRemaningValueToPay() {
      if (!this.totalAmount) {
        return false
      }
      return this.totalAmount - this.getTotal() !== 0
    },
    remainingValueToPayText() {
      if(!this.totalAmount) {
        return ''
      }

      const total = this.totalAmount - this.getTotal()
      if (total > 0) {
        return `Faltam ${parseNumberToMoney(
          total
        )} para completar o valor total`
      }
      return `${parseNumberToMoney(-total)} acima do valor total`
    },
    totalValueCreditPatient() {
      return this.bill.payments.find(
        payment => payment.payment_method?.name === 'Crédito do paciente'
      )?.amount || 0
    },
    paymentMethodsFiltered() {
      return this.paymentMethods.filter(item => item.name !== 'Crédito do paciente')
    }
  },
  methods: {
    parseNumberToMoney,
    getDefaultPaymentMethod: () => ({
      payment_method: null,
      amount: 0,
      due_date: null,
      bank_account: null,
      installments: []
    }),
    addNewPaymentOption() {
      this.bill.payments.push(this.getDefaultPaymentMethod())
    },
    getTotalWithTax() {
      if (!this.bill.payments) {
        return 0
      }
      const total = this.bill.payments.reduce((acc, payment) => {
        if(!payment.installments?.length) acc += payment.amount
        acc += payment.installments.reduce((acc, installment) => {
          acc += installment.amount
          return acc
        }, 0)
        return acc
      }, 0)
      return total
    },
    getTotal() {
      if (!this.bill.payments) {
        return 0
      }
      const total = this.bill.payments.reduce((acc, payment) => {
        acc += payment.amount
        return acc
      }, 0)
      return total
    },
    async removePaymentOption(index) {
      if (this.bill.payments[index].id) {
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">Deseja realmente excluir este método de pagamento? Os parcelamentos internos também serão excluídos e esta ação não poderá ser desfeita.</div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then(res => {
          if (res.isConfirmed) {
            this.deletePayment(index, this.bill.payments[index].id)
          }
        })
        return
      }
      this.bill.payments.splice(index, 1)
    },
    getPaymentMethods() {
      this.api
        .getPaymentMethodsBillToReceive(this.clinic.id, ['out'])
        .then(
          res => (this.paymentMethods = res.data.filter(item => item.status))
        )
        .catch(err => this.$toast.error(err.message))
    },
    getBankAccounts() {
      this.api
        .getBankAccountBillToReceive(this.clinic.id)
        .then(res => (this.bankAccounts = res.data.filter(item => item.status)))
        .catch(err => this.$toast.error(err.message))
    },
    deletePayment(index, id) {
      this.api
        .deletePayment(id)
        .then(() => {
          this.$toast.success('Método de pagamento removido com sucesso')
          this.bill.payments.splice(index, 1)
        })
        .catch(err => this.$toast.error(err.message))
    },
    handleCreditPatientPayment(amount) {
      this.bill.payments[0].payment_method = this.paymentMethods.find(
        item => item.name === 'Crédito do paciente'
      )
      this.bill.payments[0].due_date = new Date()
      this.bill.payments[0].amount = 0
    },
    setCrediPatientAmount(amount) {
      this.bill.payments[0].amount = amount
    },
    removeCreditPatientPayment() {
      this.bill.payments = [...this.bill.payments.filter(
        item => item.payment_method?.name !== 'Crédito do paciente'
      )]

      if (this.bill.payments.length === 0) {
        this.addNewPaymentOption()
      
      }
    }
  },
  filters: {
    parseToMoney(value) {
      return parseNumberToMoney(value)
    }
  },
  watch: {
    bill: {
      handler(newValue) {
        // console.log(newValue)
      },
      immediate: true 
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

.total {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.payments-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed var(--neutral-200);
  padding: 20px 0;
}

.new-payment-button {
  color: var(--blue-500);
}

.remaining-to-pay {
  display: flex;
  margin: 1rem 0;
  justify-content: flex-end;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    background: #fff0e8;
    padding: 0.4rem 0.6rem;
    letter-spacing: 1px;
    font-family: 'Nunito Sans';
    color: var(--states-error);
    border-radius: calc($border-radius-base/2);

    > span {
      font-weight: 700;
    }
  }
}
</style>
